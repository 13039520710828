/* Hobbies section styling */
.hobbies-section {
    background: linear-gradient(to bottom, #C1D0D7 70%, #ECE8DE 30%); /* Två färger som delar sektionen i två */
    padding: 0px 15%;
}

.hobbies-section h3 {
    padding-left: 20px;
}

/* Grid layout for hobby cards */
.hobbies-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Max tre kolumner */
    gap: 0px;
}

/* Each hobby card styling */
.hobby-card {
    padding: 20px;
    height: 100%;
}

.hobby-card img {
    width: 100%;
    border-radius: 0 12px 12px 12px;
    height: 75%;
    object-fit: cover;
}

.hobby-card p {
    margin-bottom: 15px; /* Space between text and image */
}

@media screen and (max-width: 1040px) {

    .hobbies-grid {
        grid-template-columns: repeat(2, 1fr); /* Två kolumner på mindre skärmar */
    }
    .hobbies-section{
        padding: 0px 5%;
        background: linear-gradient(to bottom, #C1D0D7 80%, #ECE8DE 20%); /* Två färger som delar sektionen i två */

    }

    .hobby-card{
        margin-bottom: -10%;
    }
}

@media screen and (max-width: 700px){
    .hobbies-grid {
        grid-template-columns: repeat(1, 1fr); /* Två kolumner på mindre skärmar */

    }

    .hobby-card{
        height: 70vh;
    }
}
