.tabs-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 7%; /* Justerar marginalen så den inte är för tight */
}

  
.tabs {
    display: flex;
    justify-content: right;
    margin-bottom: 20px;
    padding-right: 1%;
}
  
.tabs button {
    padding: 10px 20px;
    margin: 0 4px;
    cursor: pointer;
    background-color: transparent;
    border: 2px solid #000;
    border-radius: 20px;
}

.tabs button.active {
    background-color: black;
    color: #ECE8DE !important;
}

.tabs button:hover {
    background-color: #E0DCD2;
}

.tabs button.active:hover {
    background-color: black;
    color: #ECE8DE;
}

button{
    font-size: 14px;
}

.tabs button {
    color: black !important;
}


/* Projects grid styling */
.projects-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 20px;
    margin-left: 7%;
    margin-right: 7%;
    margin-bottom: 7%;
}

/* Project card styling */
.project-card {
    transition: transform 0.5s ease;
    width: 100%; /* Fast bredd */
    height: 27vw; /* Fast höjd */
    transform: scale(1);
}

.project-card img {
    width: 100%;
    height: 82%;
    object-fit: cover;
    border-radius: 8px 8px 8px 0;
}

.project-card h3:hover {
    transition: color 0.3s ease;
    color: #2F2E2C;
}


.project-card:hover {
    transform: scale(1.03);
}



.project-card p {
    margin-bottom: 15px;
}

.project-card h3 {
    margin-top: 8px;
}

.projects h2 {
    text-align: center;
    margin-bottom: 18px;
}


@media screen and (max-width: 1200px){

    .projects {
        margin-top: 12%;
    }

    .projects-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 50px 20px;
    }

    .project-card {
        width: 100%;
        height: 45vw;
    }

    .tabs-container {
        flex-direction: column;
        align-items: center; 
        gap: 20px 20px; 
    }
    
    .tabs {
        width: 100%;
        justify-content: center; 
    }

    .projects h2{
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 700px){

    .projects-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .project-card {
        width: 100%;
        height: 85vw;
    }
}