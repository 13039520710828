/* Journey section styling */
.journey-section {
    background-color: #C1D0D7; 
    padding: 100px 10% 50px 10%;
  }
  
  .journey-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .journey-text {
    flex: 1;
    max-width: 60%;
  }
  
  .journey-text h2 {
    margin-bottom: 20px;
  }
  
  .journey-text p {
    line-height: 1.6;
  }
  
  .journey-tools {
    flex: 1;
    max-width: 35%;
    margin-top: 3%;
  }
  .journey-tools p {
    line-height: 1.6;
  }
  
  /* För att se till att layouten anpassar sig för mindre skärmar */
  @media (max-width: 768px) {
    .journey-content {
      flex-direction: column;
    }
  
    .journey-text, .journey-tools {
      max-width: 100%;
    }
  
    .journey-text {
      margin-bottom: 30px;
    }
  }
  