@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Outfit', sans-serif;
}

body{
  background-color: #ECE8DE;
}

h1, h2, p, a{
    color: black;
    text-decoration: none;
}

ul{
    list-style: none;
}

h1{
  font-size: clamp(20px, 5vw, 5rem);
}

p{
    font-size: 1rem;
}

h2{
    font-size: 1.4rem;
    font-weight: 600;
}

h3{
    font-size: 1.2rem;
    font-weight: 600;
}

h4{
    font-weight: 600;
}

.btn{
    padding: 0.6rem 1.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    background-color: transparent;
    border: 2px solid white;
    color: white;
    cursor: pointer;
    transition: 0.5s;
}

.btn-light{
    background-color: white;
    color: black;
}