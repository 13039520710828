.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;

}

.nav-menu{
    display: flex;
}

.header h1{
    font-size: 1.5rem;
    font-weight: 600;
}

.nav-menu li{
    padding: 0 1rem;
}

.nav-menu li a{
    font-size:  1.2rem;
    font-weight: 500;
    cursor: pointer;
}

.nav-menu li a:hover{
    color: #2F2E2C;
}

.hamburger{
    display: none;
    z-index: 1;
}

.header.hide-about .about-link {
    display: none;
}

@media screen and (max-width: 1040px){
    .nav-menu{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.9);
        position: fixed;
        top: 0;
        left: 100%;
        z-index: 1;
        transition: 0.5s;
    }

    .nav-menu.active{
        left: 0;
    }

    .nav-menu li{
        padding: 1rem 0;
    }

    .nav-menu li a{
        font-size: 2rem;
        color: white;
    }

    .hamburger{
        display: initial;
        position: fixed;
        top: 20px; /* Placera den i toppen */
        right: 20px; /* Placera den till höger */
    }

    
}