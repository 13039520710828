/* Baslayout för projektmallen */
.project-template {
    padding: 20px;
    margin-left: 7%;
    margin-right: 7%;
}

/* Header Section */
.project-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0px;
}

.project-text {
    max-width: 60%;
}

.project-text h1 {
    font-size: 2.5rem;
    margin-top: 10%;
}


.project-text p {
    line-height: 1.6;
    margin-bottom: 5%;
}

.project-image img {
    max-width: 100%;
    height: 70vh;
    border-radius: 10px;
}

/* Columns Section */
.project-section {
    margin-bottom: 80px;
}

.section-columns {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.column {
    flex: 1;
}

.column h3 {
    padding-top: 10px;
}


.column p {
    line-height: 1.6;
}

.column-images {
    display: flex;
    gap: 20px;
    width: 41vw;
    box-sizing: border-box;
    margin-top: 5%;
    margin-bottom: 5%;
}

.column-images img {
    flex: 1;
    border-radius: 0 10px 10px 10px;
    max-width: 100%;

}

/* Gallery Section */
.project-gallery {
    margin-bottom: 40px;
}

.project-gallery h2 {
    margin-bottom: 20px;
}

.gallery-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.gallery-row img {
    flex: 1;
    max-width: 100%;
    border-radius: 0;
}

.gallery-row.full-width img {
    width: 100%;
}

  
/* Responsiv design */
@media (max-width: 1040px) {
.project-header {
    flex-direction: column;
    align-items: center;
}


.project-template{
    margin-left: 5%;
    margin-right: 5%;
}

.project-text {
    max-width: 100%;
    margin-bottom: 20px;
}

.column-images {
    width: 100%;
}

.section-columns {
    flex-direction: column;
}

.gallery-row {
    flex-direction: column;
}

.column-images {
    flex-direction: column;
}
}
