.footer {
    background-color: #ECE8DE;
    padding: 80px 20px;
    text-align: center;
    padding-bottom: 80px;
  }
  
  .footer h2 {
    font-size: 1.75rem;
    margin-bottom: 10px;
  }
  
  .footer p {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .footer a:hover{
    color: #2F2E2C;
    font-weight: 600;
  }
  
  .footer-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .footer-icons a {
    color: black;
    transition: color 0.3s ease;
  }
  
  .footer-icons a:hover {
    color: #2F2E2C;
  }
  