.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center; 
    height: 80vh; 
}
  
.text-area {
    margin-left: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-area {
    display: flex;
    justify-content: center;
    margin-left: -15%;
}

.image-area img {
    max-width: 55%;
    max-height: 60vh;
    border-radius: 60px 60px 60px 0px;
    padding: 10px;
}
  
p {
    padding-top: 10px;
    font-size: 1.1rem;
}

@media screen and (max-width: 1040px){
    
    .grid-container {
        height: 70vh; 
    }

    .image-area img {
        max-width: 90vw;
        max-height: 50vh;
        object-fit: cover;
        border-radius: 60px 60px 60px 0px;
    }

    .text-area p{
        line-height: 1.5;
    }
    
}

@media screen and (max-width: 700px){
    .grid-container {
        grid-template-columns: 1fr;
        height: 100vh;
        margin-left: 7%;
        margin-right: 7%;
    }

    .text-area{
        margin-left: 0;
    }

    .text-area h1{
        font-size: 2.5rem;
    }

    .image-area img {
        width: 100%;
        max-height: 60vh;
        object-position: center;
    }

    .image-area {
        margin-left: 0;
    }



}